export {Footnotes}

const Footnotes = () => {

    var footnotes = document.getElementsByClassName("footnote");
    var ajaxContainer = document.getElementById("ajax-container");

    console.log("footnotes");
    var i = 0;

    Array.from(footnotes).forEach(function (el){

        var Num = i++;
        var FnId = el.firstChild.getAttribute("href");
        var FnEl = document.querySelector(FnId);

        el.addEventListener("mouseenter", function (){
            if(document.documentElement.classList.contains("no-touch")) {
                FnEl.style.display = "block";
                var height = FnEl.offsetHeight;
                FnEl.style = "--height:" + height + "px; display:block;";
            }
        })

        el.addEventListener("mouseleave", function (){
            if(document.documentElement.classList.contains("no-touch")) {
                FnEl.style = "";
            }
        })

        el.addEventListener("click", function (){
            if(document.documentElement.classList.contains("touch")) {
                FnEl.style.display = "block";
                var height = FnEl.offsetHeight;
                FnEl.style = "--height:" + height + "px; display:block;";
            }
        })

        ajaxContainer.addEventListener("scroll", function (){
            FnEl.style = "";
        })
    });



};