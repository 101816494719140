import {Carousel} from "./carousel";
import {Footnotes} from "./footnotes";
import {CloseMenu} from "./menu";
import {SetBackgroundImagePosition, RemoveBluredTitles} from "./home";

export const LoadAjax = (Url, Id, Container, Event) => {

        const controller = new AbortController();
        const { signal } = controller;

        var AjaxContainer = document.getElementById(Container);
        var AjaxContent = AjaxContainer.getElementsByClassName("ajax-content")[0];
        var Body = document.body;
        AjaxContent.style.opacity = "0";
        AjaxContainer.classList.remove('hide');
        AjaxContainer.setAttribute("data-page", Id.replace("/",""));
        Body.classList.add('ajax-container-visible');
        fetch(Url, {signal}).then(function (response) {
            return response.json();
            controller.abort();
        }).then(function (content) {
            AjaxContainer.scrollTop = 0;
            AjaxContent.innerHTML = content;
            AjaxContent.style.opacity = "1";
            if(Event !== "popstate"){
                history.pushState(null, null, Id);
            }
            Carousel();
            Init();
        });

    }

const Init = () => {
    var CloseButtons = document.getElementsByClassName('close-btn');
    var Body = document.body;
    var AjaxContainer = document.getElementById("ajax-container");
    var AjaxContent = AjaxContainer.getElementsByClassName("ajax-content")[0];
    var Posts = document.getElementsByClassName('post');
    var i;
    var MenuBtn = document.querySelector(".menu-toggle[data-target='filter-container'] .btn-content");

        MenuBtn.innerHTML = "Edito";



    var MenuLinks = document.querySelectorAll("a.ajax-link-in");
    var i;

    for (i = 0; i < MenuLinks.length; i++) {
        MenuLinks[i].addEventListener('click', function (e) {
            e.preventDefault();
            var Container = this.getAttribute('data-container');
            console.log(Container);

            e.preventDefault();
            var Id = this.getAttribute("href");
            var Url = Id + ".json";
            CloseMenu();
            LoadAjax(Url, Id, Container);
        });
    }

    SetBackgroundImagePosition();
    RemoveBluredTitles();
    Footnotes();

}
