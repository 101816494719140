export {loadPage, SetBackgroundImagePosition, RemoveBluredTitles}

const loadPage = () => {
    console.log("Loaded");
};






const SetBackgroundImagePosition = () => {
    var scrollContainer = document.getElementById("main");
    var articles = document.getElementsByClassName("post");
    var i;

    for (i = 0; i < articles.length; i++) {
        var MainScrollTop = document.getElementById('main').scrollTop
        var postOffsetTop = ~~Math.max(0, MainScrollTop - articles[i].offsetHeight);
        var postHeight = articles[i].offsetHeight;
        var postOffset = articles[i].offsetTop;
        var Title = articles[i].querySelector("h1");
        var TitleDistance = articles[i].querySelector(".title").offsetTop + 60;
        Title.dataset.offset = TitleDistance;
        Title.dataset.postoffset = postOffset;
        Title.dataset.mainscrolltop = MainScrollTop;
        Title.dataset.delta = postOffsetTop;
        Title.style.backgroundPosition = "";
        Title.style.backgroundPosition = "0 " + -TitleDistance + "px";



        if(postHeight + postOffset < MainScrollTop){
            articles[i].dataset.in = "not";
            articles[i].dataset.result = postHeight + postOffset;
            articles[i].dataset.main = MainScrollTop;
        }else{
            articles[i].dataset.in = "in";
        }

        console.log('set back');
    }
}

const RemoveBluredTitles = () => {

    var PostsTitles = document.getElementsByClassName("post h1");

    Array.from(PostsTitles).forEach(function (el) {


        if (window.innerWidth < 768) {
            var PostsTitles = document.querySelectorAll(".post h1");
            el.style.backgroundClip = "unset";
            el.style.WebkitTextFillColor = "black";
            el.style.backgroundPosition = "-40px 0px";
        } else {
            SetBackgroundImagePosition(scrollContainer);
        }

    });
}



