import {loadPage, SetBackgroundImagePosition, RemoveBluredTitles, ScrollHandler} from "./home";

export {OpenCloseMenu, CloseMenu, SortPosts}

const OpenCloseMenu = () => {
    var MenusButtons = document.getElementsByClassName("menu-toggle");
    var PostsTitles = document.querySelectorAll(".post h1");
    var body = document.body;
    var AjaxContainer = document.getElementById("ajax-container");
    var i;
    var j;

    for (i = 0; i < MenusButtons.length; i++) {
        MenusButtons[i].addEventListener('click', function () {
            var ButtonTarget = this.getAttribute('data-target');
            var Target = document.getElementById(ButtonTarget);
            var Others = document.querySelector(".btn-target:not(#" + ButtonTarget + ")");

            if (body.classList.contains("menu-active") && Target.classList.contains('active')) {
                body.classList.remove("menu-active");

                for (j = 0; j < PostsTitles.length; j++) {
                    if (typeof PostsTitles[j] != 'undefined' && PostsTitles != null) {
                        PostsTitles[j].style.display = null;
                    }
                }
            } else {
                body.classList.add("menu-active");


                for (j = 0; j < PostsTitles.length; j++) {
                    if (typeof PostsTitles[j] != 'undefined' && PostsTitles != null) {
                        PostsTitles[j].style.display = "none";
                    }
                }
            }

            Others.classList.remove("active");
            Target.classList.toggle("active");
        });
    }

    BackHome();
}

const CloseMenu = () => {

    var MenuContainers = document.getElementsByClassName("btn-target");
    var Main = document.getElementsByTagName("main")[0];
    var body = document.body;
    var PostsTitles = document.querySelectorAll(".post h1");
    var Posts = document.querySelectorAll(".post");
    var i;
    var j;


    for (i = 0; i < MenuContainers.length; i++) {
        MenuContainers[i].classList.remove("active");
        body.classList.remove("menu-active");
        for (j = 0; j < PostsTitles.length; j++) {
            PostsTitles[j].style.display =  null;
        }
    }

}

const SortPosts = () => {
    var FilterButtons = document.querySelectorAll(".filter");
    var Posts = document.querySelectorAll(".post");
    var MenuBtn = document.querySelector(".menu-toggle[data-target='filter-container'] .btn-content");

    Array.from(FilterButtons).forEach(function (btn) {

        btn.addEventListener("click", function (e) {
            e.preventDefault();
            var cat = btn.getAttribute("href");
            var catText = btn.querySelector("h1").innerHTML;
            var newsletter = document.getElementById('newsletter');

            newsletter.style.order = '999';
            MenuBtn.innerHTML = catText;
            SetBackgroundImagePosition();

            Array.from(Posts).forEach(function (post) {

                if (post.classList.contains(cat)) {
                    post.style.display = "block";
                } else {
                    post.style.display = "none";
                }

            });

        });
    });
}

const BackHome = () => {

    var BackHome = document.querySelectorAll(".back");
    var AjaxContainer = document.getElementById("ajax-container");
    var Main = document.getElementsByTagName("main")[0];
    var Posts = document.querySelectorAll(".post");
    var MenuBtn = document.querySelector(".menu-toggle[data-target='filter-container'] .btn-content");


    Array.from(BackHome).forEach(function (el){

        el.addEventListener("click", function (e){
            e.preventDefault();
            Main.scrollTo(0, 0);
            CloseMenu();
            AjaxContainer.classList.add("hide");

            var newsletter = document.getElementById('newsletter');
            newsletter.style.order = '';

            Array.from(Posts).forEach(function (post) {
                post.style.display = "block";
            });

            MenuBtn.innerHTML = "Edito";

        })

    })

}