export {Ad}

import {CloseMenu} from "./menu";


const Ad = () => {

    var AdContainer = document.getElementById("ad");
    var CloseBtn = AdContainer.querySelector(".close-btn");
    var body = document.body;
    var MenusButtons = document.getElementsByClassName("menu-toggle");
    var clicked = false;

    document.addEventListener("click", function (){
       clicked = true;
    });

    setTimeout(function (){
        if(clicked == false) {
            CloseMenu();
            AdContainer.classList.remove("hide");
            body.classList.add("menu-active");
            Array.from(MenusButtons).forEach(function (el) {
                el.classList.add("hide");
            });
        }
    }, 2000);


    CloseBtn.addEventListener("click", function (){

        this.parentElement.classList.add("hide");
        body.classList.remove("menu-active");
        Array.from(MenusButtons).forEach(function (el){
            el.classList.remove("hide");
        });
    });



}