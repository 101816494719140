__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage, SetBackgroundImagePosition, RemoveBluredTitles, ScrollHandler} from "./_modules/home";
import {LoadAjax} from "./_modules/ajax";
import {OpenCloseMenu, CloseMenu, SortPosts} from "./_modules/menu";
import {Carousel} from "./_modules/carousel";
import {Ad} from "./_modules/ad";

var sh = require('scroll-handler');

/*var $ = require('jquery');*/
/*var imagesLoaded = require('imagesloaded');*/


document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();
    Carousel();
    SortPosts();
    SetBackgroundImagePosition();
    RemoveBluredTitles();



    var scrollContainer = document.getElementById("main");


    scrollContainer.addEventListener('scroll', function () {
        SetBackgroundImagePosition();
    });

    window.addEventListener("resize", function (){
        SetBackgroundImagePosition();
        RemoveBluredTitles();
    });


    // OPEN/CLOSE MENU

    OpenCloseMenu();


    //

    var MenuContainer = document.getElementsByClassName("btn-target");

    Array.from(MenuContainer).forEach(function (el) {

        el.addEventListener("click", function (){
            CloseMenu();
        });
    });



    // AJAX LOAD

    // Onclick //

    var MenuLinks = document.querySelectorAll("a.ajax-link");
    var i;

    for (i = 0; i < MenuLinks.length; i++) {
        MenuLinks[i].addEventListener('click', function (e) {
            e.preventDefault();
            var Container = this.getAttribute('data-container');

            e.preventDefault();
            var Id = this.getAttribute("href");
            var Url = Id + ".json";
            CloseMenu();
            LoadAjax(Url, Id, Container);
        });
    }

    // OnLoad //

    if (window.location.pathname !== '/' ){
        var Id = window.location.pathname;
        var Url = Id + ".json";
        var Container = "ajax-container";
        LoadAjax(Url, Id, Container);
    }else{
        Ad();
    }

    // Close Ajax

    var CloseButtons = document.getElementsByClassName('close-btn');
    var Body = document.body;
    var AjaxContainer = document.getElementById("ajax-container");
    var AjaxContent = AjaxContainer.getElementsByClassName("ajax-content")[0];
    var Posts = document.getElementsByClassName('post');
    var i;

    for (i = 0; i < CloseButtons.length; i++){
        CloseButtons[i].addEventListener("click", function (){

            if(AjaxContainer.getAttribute("data-page").includes("creative-bureau/") ){
                var Url = "/creative-bureau.json";
                var Id = "creative-bureau";
                var Container = "ajax-container";
                LoadAjax(Url, Id, Container);
            }else {

                AjaxContainer.classList.add("hide");

                AjaxContainer.addEventListener("transitionend", function () {
                    if (AjaxContainer.classList.contains("hide")) {
                        AjaxContent.innerHTML = '';
                    }
                });

                Body.classList.remove('ajax-container-visible');
                history.pushState(null, null, "/");
                Array.from(Posts).forEach(function (post) {
                    post.style.display = "block";
                });
            }
        })
    }


    window.addEventListener('popstate', function(event) {

        var Event = event.type;
        var Container = "ajax-container";

        if(location.pathname == "/"){
            document.getElementById(Container).classList.add('hide');
        }else{
            var Id = window.location.pathname;
            var Url = Id + ".json";
            var Container = "ajax-container";
            LoadAjax(Url, Id, Container, Event);
        }

    });

});