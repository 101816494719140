import Swiper, { Navigation, EffectCoverflow } from 'swiper';
import 'swiper/css';



export const Carousel = () => {

    const swiper = new Swiper('.swiper-container', {
        modules: [Navigation, EffectCoverflow],
        direction: 'horizontal',
        on: {
            init: function () {
                console.log('swiper initialized');

                var Index = this.activeIndex;
                var El = this.slides[Index];
                var ElLegend = El.getAttribute("data-legend");
                var SwiperLegend = document.querySelector(".swiper-legend");

                SwiperLegend.innerHTML = ElLegend;
            },
            slideChangeTransitionStart: function (){
                var SwiperLegend = document.querySelector(".swiper-legend");
                SwiperLegend.style.opacity = "0";
            },
            slideChangeTransitionEnd: function (){
                var SwiperLegend = document.querySelector(".swiper-legend");
                SwiperLegend.style.opacity = "1";

                var Index = this.activeIndex;
                var El = this.slides[Index];
                var ElLegend = El.getAttribute("data-legend");
                var SwiperLegend = document.querySelector(".swiper-legend");

                SwiperLegend.innerHTML = ElLegend;
            }
        },
        loop: true,
        effect: 'slide',
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        centeredSlides: true,
        spaceBetween: 20,
        slidesPerView: 1.2,
        slidesPerGroup: 1,
        speed: 0,

        breakpoints: {
            768: {
                slidesPerView: 1.1,
                slidesPerGroup: 1,
            },
        }
    })
};